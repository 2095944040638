import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layoutNoPodcastHeader"
import Img from "gatsby-image"
import { Container, Row, Col, Card } from "react-bootstrap"
import SEO from "../components/seo"
import AudioPlayer from 'react-h5-audio-player';
import faceBook from "../images/facebook-icon.jpg"

export default ({ data }) => {
  const explore = data.markdownRemark
  return (
    <Layout>
      <SEO title={explore.frontmatter.title + " | Our Members"} />
      <Container className="text-center">


      
        <h1 className="mt-3 mb-3">{explore.frontmatter.title}</h1>

        <AudioPlayer
                          src={explore.frontmatter.slugOfAudio}
                          layout="horizontal-reverse"
                          customAdditionalControls={[]}
                          customVolumeControls={[]}
                          className="singleCol centeredCol"
                  />
          </Container>
          <hr />
          <Container>
              {data.markdownRemark.frontmatter.Image01 ? 
          (
            <div className="featured-thumbnail">
              <p><Img 
                  fluid={data.markdownRemark.frontmatter.Image01.childImageSharp.fluid} fadeIn 
                  />
              </p>
            </div>
          ) : 
            null 
        }
        <Row>
          <Col lg={4} md={5}>
            <Card className="mb-4">
              <Card.Body>
              {explore.frontmatter.phone ? 
                    (
                      <Card.Title><p><strong>Contact {explore.frontmatter.title}</strong></p></Card.Title>
                    ) : 
                      <div><Link to="/contact/">Contact the Kia Orana Podcast</Link> for more information about this podcast and business/guest.</div> 
                  }
                {/* <Card.Title><p><strong>Contact {explore.frontmatter.title}</strong></p></Card.Title> */}
                <Card.Text>
                  {explore.frontmatter.phone ? 
                    (
                      <div>
                      <p>Phone<br />
                      <strong>{explore.frontmatter.phone}</strong>
                      </p>
                      
                      </div>
                    ) : 
                      null 
                  }

                  {explore.frontmatter.url ? 
                    (
                      <div>
                      <p>Website<br />
                      <strong>

                        {/* <a href={explore.frontmatter.url}>
                          {explore.frontmatter.url}
                          </a> */}
                        <a href={"http://" + explore.frontmatter.url}>{explore.frontmatter.url}</a>
                      </strong>
                      </p>
                      
                      </div>
                    ) : 
                      null 
                  }

                  {explore.frontmatter.text ? 
                    (
                      <div>
                      <p>Text<br />
                      <strong>{explore.frontmatter.text}</strong>
                      </p>
                      
                      </div>
                    ) : 
                      null 
                  }

                  {explore.frontmatter.text ? 
                    (
                      <div>
                      <p>Email<br />
                      <strong><a href={"mailto:" + explore.frontmatter.email}>{explore.frontmatter.email}</a></strong>
                      </p>
                      
                      </div>
                    ) : 
                      null 
                  }
                  
                  {explore.frontmatter.facebook ? 
                    (
                      <div>
                        <p>On Social Media<br />
                        <a href={explore.frontmatter.facebook}>
                          <img src={faceBook} alt="Facebook Icon" className="facebookMaxWidth mr-2" />
                        </a>
                        </p>
                      </div>
                    ) : 
                      null 
                  }

                  {explore.frontmatter.locatedIn ? 
                    (
                      <div>
                        <p>Located in<br />
                        <strong>{explore.frontmatter.locatedIn}</strong>
                        </p>
                      </div>
                    ) : 
                      null 
                  }

                  
                  
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={8} md={7}>
          <div>
                {data.markdownRemark.html ? 
            (
              <article>
              <div dangerouslySetInnerHTML={{ __html: explore.html }} />

              </article>
            ) : 
              null 
          }
                </div>
          </Col>
        </Row>
        
        
        
      </Container>
        


                  

                  {/* {data.markdownRemark.frontmatter.featuredImage200 ? (
                    <div className="featured-thumbnail">
                      <Img fluid={data.markdownRemark.frontmatter.featuredImage200.childImageSharp.fluid} />
                    </div>
                  ) : null } */}




        {/* 
        {data.markdownRemark.frontmatter.featuredImage.childImageSharp.fluid === '' && (
            <span>This is only a test</span>
          )}
        {data.markdownRemark.frontmatter.featuredImage.childImageSharp.fluid !== '' && (
            <span>This is only another test</span>
          )} */}
        
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        category
        url
        phone
        text
        email
        locatedIn
        facebook
        slugOfAudio
        Image01 {
          childImageSharp {
            fluid(maxWidth: 1120) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`  